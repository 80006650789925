import React from "react"
import {VictoryAxis, VictoryBar, VictoryChart, VictoryContainer, VictoryLabel, VictoryTheme} from "victory";

type props = {
   stats: any
}
const Pay = ({stats}: props) => {
    if (stats === undefined) {
        return <></>
    }

    let labels: string[] = [];
    let data: any[] = [];
    const labelsText   = {
        prepay: 'Карта\nклиента',
        cash: 'Наличные',
        qr: 'QR',
        terminal: 'Терминал'
    }

    Object.keys(stats).forEach((key:string) => {
        if (key.startsWith('daily_pay_')) {
            if (stats[key] > 0) {
                labels.push(key.replace('daily_pay_', ''))
                data.push({
                    type: key.replace('daily_pay_', ''),
                    amount: stats[key],
                    fill: "#ffffff"
                })
            }
        }
    })

    if (data.length === 0) {
        return <div>
            Нет данных
        </div>
    }

    return <div>
        Поступления за сутки

        <VictoryChart
            width={450}
            height={300}
            maxDomain={{x: data.length}}
            padding={{top:50, bottom: 70, left:50, right:50}}
            theme={VictoryTheme.material}
            containerComponent={<VictoryContainer className="myFinanceChart"/>}>
            <VictoryAxis
                style={{tickLabels: {fontSize: 25, fill: "white"}}}
                tickValues={labels}
                tickFormat={(t) => labelsText[t as keyof typeof labelsText]}
                tickLabelComponent={<VictoryLabel angle={-75} textAnchor="end"/>}/>

            <VictoryBar
                style={{
                    data: {
                        fill: ({datum}) => datum.fill,
                        fillOpacity: 0.7,
                        strokeWidth: 3
                    },
                    labels: {
                        fontSize: 25,
                        fill: "white"
                    }}}
                barRatio={1} data={data} x="type" y="amount"
                labels={({datum}) => `${datum.amount}`}/>

        </VictoryChart>

    </div>
}

export default Pay
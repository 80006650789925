import {observable, action, makeAutoObservable, runInAction} from "mobx"
import {adminApi} from '../api/admin'

export class AdminStore {
    constructor() {
        makeAutoObservable(this)
    }

    @observable QR?: any;

    @action loadQr = () => {
        adminApi.getQR().then(data => {
            this.setQR(data)
        })
    }

    @action setQR = (qr: string) => {
        this.QR = qr;
    }
}


import React from "react"
import {VictoryAxis, VictoryBar, VictoryChart, VictoryContainer, VictoryStack, VictoryTheme} from "victory";

type props = {
    market: any
}
const Water = ({market}: props) => {
    const data = [
        {col: 1, val: parseFloat(market?.stats?.daily_water), fill: '#d5e8d4'},
        {col: 2, val: market?.condition?.w, fill: '#9bc1f8'},
    ];

    return <div>
        Вода
        <VictoryChart maxDomain={{x: 2}} theme={VictoryTheme.material} containerComponent={<VictoryContainer className="myProductChart"/>}>
            <VictoryAxis tickValues={[1, 2]} tickFormat={["", ""]}/>
            <VictoryBar barRatio={2} data={data} x="col" y="val"
                labels={({datum}) => `${datum.val}`}
                style={{
                    data: {
                        fill: ({datum}) => datum.fill,
                        fillOpacity: 0.7,
                        strokeWidth: 3
                    },
                    labels: {
                        fontSize: 25,
                        fill: "white"
                    }
                }}/>
        </VictoryChart>
        <div><span style={{backgroundColor: "#d5e8d4"}}>&nbsp;</span> Продано за сутки</div>
        <div><span style={{backgroundColor: "#9bc1f8"}}>&nbsp;</span> Остаток в Баке</div>
    </div>
}

export default Water
import React, {useEffect, useState} from "react"
import {observer} from "mobx-react-lite"
import {useRootStore} from "../../RootStateContext"
import Loader from "../Loader"
const QRAccess = observer( () => {
    const {adminStore} = useRootStore()
    const {QR, loadQr} = adminStore

    useEffect(() => {
        loadQr()
    }, []);
    if (QR === undefined) {

        return <Loader></Loader>
    }
    return (
        <div className="details_block details_block--qr">
            <h4 className="text-center details__header mb-3">Доступ</h4>
            <img src={'data:image/png;base64,' + QR} alt="" />
            <p>Поднесите QR-код к считывателю</p>
        </div>
    )
})

export default QRAccess
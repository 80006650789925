import React from "react"
import {MarketsStore} from "./stores/MarketsStore"
import {AuthStore} from "./stores/AuthStore"
import {UserStore} from "./stores/UsersStore"
import {FirmStore} from "./stores/FirmStore"
import {BrandStore} from "./stores/BrandStore"
import {PriceListStore} from "./stores/PriceListStore"
import {ConfigurationStore} from "./stores/ConfigurationStore";
import {AdminStore} from "./stores/AdminStore"

type RootStateContextValue = {
    marketsStore: MarketsStore,
    authStore: AuthStore,
    userStore: UserStore,
    firmsStore: FirmStore,
    brandStore: BrandStore,
    priceListStore: PriceListStore,
    configurationStore: ConfigurationStore,
    adminStore: AdminStore
}

const RootStateContext = React.createContext<RootStateContextValue>({} as RootStateContextValue)

const RootStateValue: RootStateContextValue = {
    marketsStore: new MarketsStore(),
    authStore: new AuthStore(),
    userStore: new UserStore(),
    firmsStore: new FirmStore(),
    brandStore: new BrandStore(),
    priceListStore: new PriceListStore(),
    configurationStore: new ConfigurationStore(),
    adminStore: new AdminStore()
}


export const RootStateProvider: React.FC<React.PropsWithChildren<{}>> = ({children}) => {
    return (
        <RootStateContext.Provider value={RootStateValue}>
            {children}
        </RootStateContext.Provider>
    )
}

export const useRootStore = () => React.useContext(RootStateContext)
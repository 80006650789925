import api from './api'

export const adminApi = {
    getQR: async () => {
        const response = await api.get(
            'qr',
            {
                headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
            }
        )
        return response.data
    }
}


import React, {useEffect, Fragment} from 'react'
import {observer} from "mobx-react-lite"
import {useRootStore} from "./RootStateContext"
import LoginForm from "./components/LoginForm"
import Loader from "./components/Loader"
import Header from "./components/Header"
import MarketsPage from "./components/market/Page"
import Reports from "./components/reports/Page"
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom"
import ManagmentPage from './components/managment/ManagmentPage'
import QRAccess from "./components/admin/QRAccess";

const App = observer(() => {
    const {authStore} = useRootStore()

    useEffect( () => {
        authStore.authenticate()
    }, [authStore])

    if (authStore.isAuth === undefined) {
        return <Loader/>
    }

    if (authStore.isAuth === false) {
        return <LoginForm/>
    }

    return (
        <Router>
            <Fragment>
                <Header/>
                <Routes>
                    <Route path="/" element={<MarketsPage/>}/>
                    <Route path="/market/:tab" element={<MarketsPage/>}/>
                    {/* <Route path="/tasks">
                        Tasks page
                    </Route>
                    <Route path="/cards">
                        Cards page
                    </Route> */}
                    <Route path="/qr" element={<QRAccess/>}>Доступ</Route>
                    <Route path="/support">
                        Техническая поддержка
                    </Route>
                    <Route path="/reports" element={<Reports/>}/>


                    <Route path="/managment" element={<ManagmentPage/>}/>
                </Routes>
            </Fragment>
        </Router>
    );
})

export default App
import React from "react"
import {VictoryAxis, VictoryBar, VictoryChart, VictoryContainer, VictoryLabel, VictoryTheme} from "victory";

type props = {
    title: string
    cash: any
}
const Cash = ({title, cash}: props) => {

    if (cash === undefined) {
        return <></>
    }

    let data: any[] = [];
    let total: number = 0;
    Object.keys(cash).forEach((key:string) => {
        if (cash[key] > 0) {
            data.push({
                denomination: key,
                amount: cash[key],
                fill: '#ffffff',
            })
            total += parseInt(key) * cash[key]
        }
    })

    if (data.length === 0) {
        return <div>
            <div>{title}</div>
            Нет данных
        </div>
    }

    return <div>
        {title}
        <VictoryChart
            width={450}
            height={300}
            maxDomain={{x: data.length}}
            theme={VictoryTheme.material}
            containerComponent={<VictoryContainer className="myFinanceChart"/>}>
            <VictoryAxis
                style={{tickLabels: {fontSize: 25, fill: "white"}}}
                tickLabelComponent={<VictoryLabel angle={-45} textAnchor="end"/>}/>

            <VictoryBar
                style={{
                    data: {
                        fill: ({datum}) => datum.fill,
                        fillOpacity: 0.7,
                        strokeWidth: 3
                    },
                    labels: {
                        fontSize: 25,
                        fill: "white"
                    }}}
                barRatio={1} data={data} x="denomination" y="amount"
                labels={({datum}) => `${datum.amount}`}/>

        </VictoryChart>


        <div>{total}</div>

    </div>
}

export default Cash